import { extendTheme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'
import { cardTheme, drawerTheme, modalTheme, skeletonTheme, statTheme, tableTheme } from 'components/chakraComponents'
import { ThemeColors } from 'enums'

const styles = {
	global: (props) => ({
		body: {
			color: mode('gray.800', 'whiteAlpha.900')(props),
			backgroundColor: mode(ThemeColors.LIGHT_MODE.LIGHT_WHITE, ThemeColors.LIGHT_MODE.BLACK)(props),
		},
	}),
}

const colors = {
	midGray: '#323130',
}

const components = {
	Card: cardTheme,
	Table: tableTheme,
	Skeleton: skeletonTheme,
	Modal: modalTheme,
	Drawer: drawerTheme,
	Stat: statTheme,
}

const theme = extendTheme({ styles, components, colors })

export default theme
