import { cardAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys)

const baseStyle = definePartsStyle({
	container: {
		borderRadius: '20px',
		backgroundColor: 'inherit',
	},
})

export const cardTheme = defineMultiStyleConfig({ baseStyle, defaultProps: { variant: 'outline' } })
